import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
import auth from '../modules/auth'
import Main from '../views/Main.vue'
import CRMS from '../views/CRMS'
import route from './route'

Vue.use(VueRouter)

function requireAuth (to, from, next) {
  if (!auth.auth.isUserSignedIn()) {
    store.commit('setLoggedIn', false)
    next({
      path: '/login',
      query: {
        redirect: to.fullPath
      }
    })
  } else {
    next()
  }
}

const routes = [
  {
    path: '/login',
    name: 'login',
    beforeEnter (to, from, next) {
      auth.auth.getSession()
    }
  },
  {
    path: '/',
    name: 'CRM Manager',
    component: CRMS,
    beforeEnter: requireAuth
  },
  {
    path: '/health-check',
    name: 'Diagnosis',
    component: Main,
    beforeEnter: requireAuth
  },
  {
    path: '/login/oauth2/code/cognito',
    beforeEnter (to, from, next) {
      const currUrl = window.location.href
      auth.auth.parseCognitoWebResponse(currUrl)
      next()
    }
  },
  route('/login', CRMS, 'login', null, { guest: true }),
  route('/', CRMS, 'welcome', null)
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
