<template lang="pug">
    v-layout
        v-dialog(v-model="dialog", persistent)
            v-card
                v-card-title.d-flex.justify-center
                    div.actions-bar
                        v-icon(@click.native="close") mdi-close
                    v-img(
                        max-height="97",
                        max-width="100",
                        src="/img/trujay.svg"
                    )
                v-container
                    v-row(v-if="!selectedPlan")
                        v-col(cols="12")
                            div.pa-3.text-center(style="background-color: #EDF1FD;")
                                span.text-center Review our Data Management plans. Feel free to build your own Concierge plan.
                        v-col(cols="6")
                            v-card.pa-5.d-flex.flex-column.space-between
                                h4.text-h4 Starter
                                strong Free
                                p For the “spreadsheet lover”
                                div
                                v-list(dense)
                                    v-list-item-group(color="primary")
                                    v-list-item
                                        v-list-item-icon
                                        v-icon mdi-check-bold
                                        v-list-item-content
                                        v-list-item-title Unlimited Health Check
                                    v-list-item
                                        v-list-item-icon
                                        v-icon mdi-check-bold
                                        v-list-item-content
                                        v-list-item-title Chat & Email Support
                                    v-list-item
                                        v-list-item-icon
                                        v-icon mdi-check-bold
                                        v-list-item-content
                                        v-list-item-title CSV File
                                div.centered
                                v-btn.blue.text--white(dark, @click="choosePlan('Starter')") Choose Plan
                        v-col(cols="6")
                            v-card.pa-5.d-flex.flex-column.space-between
                                h4.text-h4 Concierge
                                strong Custom
                                p For the “tech-savy streamliner”
                                div
                                v-list(dense)
                                    v-list-item-group(color="primary")
                                    v-list-item
                                        v-list-item-icon
                                        v-icon mdi-check-bold
                                        v-list-item-content
                                        v-list-item-title Unlimited Health Check
                                    v-list-item
                                        v-list-item-icon
                                        v-icon mdi-check-bold
                                        v-list-item-content
                                        v-list-item-title Chat & Email Support
                                    v-list-item
                                        v-list-item-icon
                                        v-icon mdi-check-bold
                                        v-list-item-content
                                        v-list-item-title CSV File
                                    v-list-item
                                        v-list-item-icon
                                        v-icon mdi-check-bold
                                        v-list-item-content
                                        v-list-item-title Activity Tracker
                                div.subtitle Addons
                                v-checkbox.ma-0(v-model="plan.bounced", label="Bounced Email Resolution")
                                v-checkbox.ma-0(v-model="plan.deduplication", label="Deduplication")
                                v-checkbox.ma-0(v-model="plan.enrichment", label="Enrichment")
                                v-checkbox.ma-0(v-model="plan.customer", label="Customer Success Manager")
                                div.centered
                                v-btn.blue.text--white(dark, @click="choosePlan('Concierge')") Choose Plan
                    v-row(v-if="selectedPlan")
                        v-col(cols="12")
                            v-card.pa-5.d-flex.flex-column.space-between
                                p.centered
                                    h4.centered Your download should start shortly.
                                p.centered(v-if="selectedPlan === 'Starter'") Thank you and please let us know how we can help
                                p.centered(v-if="selectedPlan === 'Concierge'") Someone from <strong>Trujay</strong> will contact you <i>soon</i> to discuss how we can help!
</template>

<style>
.v-slide-group__wrapper {
  border-bottom: 1px solid #ccc;
}
.v-input__slot {
  margin: 0px !important;
}
.centered {
  display: block;
  text-align: center;
  padding: 25px 0 10px 0;
}
.subtitle {
  font-size: 1.2em;
  font-weight: 600;
  margin: 10px 0;
}
.actions-bar {
  width: 100%;
  height: auto;
  display: block;
  text-align: end;
}

</style>

<script>
import axios from 'axios'

export default {
  props: {
    dialog: {
      default: false
    }
  },
  data: function () {
    return {
      plan: {
        name: 'Starter',
        userId: null,
        bounced: false,
        deduplication: false,
        enrichment: false,
        customer: false
      },
      selectedPlan: null
    }
  },
  methods: {
    async choosePlan (name) {
      this.plan.name = name
      await axios.post(`plans/${name}`, this.plan)
      this.selectedPlan = name
    },
    close () {
      this.$emit('update:dialog', false)
    }
  }
}
</script>
