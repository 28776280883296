import './http'
import Vue from 'vue'
import _ from 'lodash'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
// import createServer from './api/mock'
import VueGoogleCharts from 'vue-google-charts'
import axios from 'axios'
import VueAxios from 'vue-axios'

Vue.use(VueGoogleCharts)
Vue.use(VueAxios, axios)

/*
// Mock server for local env
if (process.env.NODE_ENV === 'local') {
  createServer()
}
*/

window._ = _

Vue.config.productionTip = false

Vue.prototype.hasRole = function (role) {
  return this.$store.state.user && this.$store.state.user.roles.find(r => role)
}

window.$vueApp = new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
  async beforeCreate () {
    this.$store.dispatch('loadCrms')
  }
}).$mount('#app')
