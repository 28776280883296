<template lang="pug">
  v-app
    navigation
    router-view
    loader(v-if='LOADING')
</template>

<style>
/*
 *  STYLE 4
 */

*::-webkit-scrollbar-track
{
  background-color: transparent;
}

*::-webkit-scrollbar
{
  width: 2px;
  height: 2px;
  background-color: transparent;;
}

*::-webkit-scrollbar-thumb
{
  background-color: rgb(2, 27, 121);
}
.v-tabs-items .v-window__container {
  height: 100% !important;
}
tr, td {
  word-wrap: break-word;
}
.primary {
  background: linear-gradient(to left, #252F3F, #161E2E) !important;
}
.primary .v-tabs-bar {
  background: #252F3F !important;
}
.primary * {
  color: white !important;
  border-color: white;
}
.primary tr {
  border-color: white rgb(2, 27, 121) !important;
}
.primary *::before {
  border-color: rgba(255,255,255, 0.5) !important;
}
</style>

<script>
import Navigation from './components/Navigation'
import Loader from './components/Loader'
import store from './store'

export default {
  name: 'App',

  components: {
    Navigation,
    Loader
  },

  data: function () {
    return {
      drawer: false
    }
  },
  computed: {
    LOADING: function () {
      return store.state.http.is_loading
    }
  }
}

</script>
