<template lang="pug">
v-main#login-view
  v-container
    v-row
      v-col.pa-15.text-center.pb-5(cols="12")
        h1.text-h2.mb-5 Health Check Diagnosis
        div
          strong.ma-5.subtitle-1 Total Contacts: {{ subtotal.contact }}
          strong.ma-5.subtitle-1 Total Companies: {{ subtotal.lead }}
    v-row
      v-col(cols="12")
        v-card.elevation-4.px-10(flat, tile, style="cursor: pointer; position: relative;")
          v-card-title.pa-5.d-flex.justify-space-between
            //- pre {{neverBounceStatus}}
            v-img(
              max-height="55",
              max-width="182",
              :src="crmImage"
            )
            .d-flex
              v-btn.mx-2(:href="csvUrl", download="diagnosis.csv", target="_blank") Download
              v-btn.mx-2.blue.darken-3.white--text(@click="dialog=true") Fix My Data
              Plans(:dialog.sync="dialog")
          v-tabs(v-model="tab")
            v-tab(v-for="tab in tabs", v-bind:key="tab.id") {{ tab }}
          v-tabs-items.pa-10(v-model="tab")
            v-tab-item(
              key="Marketability"
            )
              v-container.fluid
                v-row
                  v-col(cols="6")
                    p Hi!
                    p We analyzed {{ totalRecords }} records and you may want to cleanse
                      v-tooltip(top)
                        template(v-slot:activator="{ on, attrs }")
                          strong.px-2.red--text(v-bind="attrs", v-on="on") {{ diagnosis.length }}
                        span "Text that will explain the criteria of the Marketable concept in a human lenguage.
                      | that are not
                      v-tooltip(top)
                        template(v-slot:activator="{ on, attrs }")
                          strong.px-2.red--text(v-bind="attrs", v-on="on") not marketable.
                        span "Text that will explain the criteria of the Marketable concept in a human lenguage.
                      br
                      | This would improve marketing KPIs and save on your monthly CRM spend.
                    p  Over
                      v-tooltip(top)
                        template(v-slot:activator="{ on, attrs }")
                          strong.px-2.green--text(v-bind="attrs", v-on="on") {{ notMarketableProperties }}
                        span "Text that will explain the criteria of the Marketable concept in a human lenguage.
                      | properties of your
                      v-tooltip(top)
                        template(v-slot:activator="{ on, attrs }")
                          strong.px-2.green--text(v-bind="attrs", v-on="on") {{ totalRecords - diagnosis.length }} marketable
                        span "Text that will explain the criteria of the Marketable concept in a human lenguage.
                      | records are missing details to create targeted, personalized experiences.
                    p Click
                      strong.px-2 Download
                      | at the top-right for the individual results
                      br
                      | or
                      strong.px-2 Fix My Data
                      | to discover how we’ll help you.
                    p Cheers,
                      br
                      | Trujay
                  v-col.d-flex.justify-center(cols="6")
                    .graphic.pa-0.ma-0(v-if="marketabilityChartData[1][1]")
                      p.pa-0.ma-0.text-center How many people can I actually market to?
                      GChart(
                        style="padding-top: -80px"
                        type="PieChart"
                        :data="marketabilityChartData"
                        :options="chartOptions"
                      )
            v-tab-item(
              key="Deliverability"
            )
              v-container.fluid
                v-row
                  v-col(cols="6")
                    p.text-h5.text-center Estimated Bounce Rate
                    p.my-10.text-h1.red--text.text-center {{ neverBounceStatus && (neverBounceStatus.bounce_estimate * 10) }}%
                    div
                      p Your contact list is very dirty and requires cleaning.
                      p Click
                        strong.px-2 Fix My Data
                        | and we’ll help you clean it.
                      p Cheers,
                        br
                        | Trujay
                  v-col.d-flex.justify-center(cols="6")
                    .graphic.pa-0.ma-0(v-if="neverBounceStatus")
                      p.pa-0.ma-0.text-center What’s the breakdown of my deliverability issues?
                      GChart(
                        style="padding-top: -80px"
                        type="PieChart"
                        :data="deliverabilityChartData"
                        :options="chartOptionsDeliverability"
                      )
</template>

<style>
.v-slide-group__wrapper {
  border-bottom: 1px solid #ccc;
}
.v-input__slot {
  margin: 0px !important;
}
.centered {
  display: block;
  text-align: center;
  padding: 25px 0 10px 0;
}
.subtitle {
  font-size: 1.2em;
  font-weight: 600;
  margin: 10px 0;
}
.actions-bar {
  width: 100%;
  height: auto;
  display: block;
  text-align: end;
}
</style>

<script>
import axios from 'axios'
import { GChart } from 'vue-google-charts'
import Plans from './../components/forms/Plans'

const STATES = {
  BUSINESS_CLAIMS: 'BUSINESS_CLAIMS',
  GRAPH: 'GRAPH',
  TABLE: 'TABLE',
  TRUJAY_HOOK: 'TRUJAY_HOOK'
}

export default {
  components: { GChart, Plans },
  name: 'Login',

  data: function () {
    return {
      plan: {
        name: 'Starter',
        userId: null,
        bounced: false,
        deduplication: false,
        enrichment: false,
        customer: false
      },
      checkbox: true,
      dialog: false,
      tab: 'Marketability',
      tabs: [
        'Marketability',
        'Deliverability'
      ],
      response: {
        marketableProperties: 0,
        notmarketableProperties: 0
      },
      totalRecords: 0,
      mainPercentage: 0,
      section: 0,
      STATES: STATES,
      state: STATES.BUSINESS_CLAIMS,
      fixMyData: false,
      showTable: false,
      showGraphics: false,
      headers: [
        {
          text: 'Object',
          align: 'start',
          sortable: false,
          value: 'object'
        },
        { text: 'Id', value: 'id' },
        { text: 'Symptoms', value: 'symptoms' },
        { text: 'Actions', value: 'actions', sortable: false }
      ],
      diagnosis: [],
      marketabilityChartData: [
        ['Error', 'Objects'],
        ['Marketable', 0],
        ['Not Marketable', 0]
      ],
      deliverabilityChartData: [
        ['Error', 'Objects'],
        ['Bad Syntax', 1000],
        ['Duplicated', 500],
        ['Invalid', 500],
        ['Unknown', 500],
        ['Disposable', 500],
        ['Catch All', 500]
      ],
      chartOptions: {
        chart: {
          title: 'Company Performance',
          subtitle: 'Sales, Expenses, and Profit: 2014-2017'
        },
        pieHole: 0.4,
        width: 690,
        height: 450,
        legend: 'none',
        colors: ['#00ff00', '#ff0000']
      },
      notMarketableProperties: 0,
      subtotal: {},
      totalCompanies: 0,
      totalContacts: 0,
      chartOptionsDeliverability: {
        chart: {
          title: 'Company Performance',
          subtitle: 'Sales, Expenses, and Profit: 2014-2017'
        },
        pieHole: 0.4,
        width: 690,
        height: 450,
        legend: 'none',
        colors: ['#1867c0', '#2A78D1']
      },
      neverBounceStatus: null,
      csvUrl: null,
      drawer: false,
      email: null,
      password: null,
      error: null,
      failedPeople: 0,
      oldPeopleRecords: 0, // total unique leads with ((-rS8Ua5aN)) & customers with ((UbdzPS2Jb)) more than 6 months ago
      crmImage: ''
    }
  },

  computed: {
    saveUpTo () {
      var result = (this.failedPeople * 100) - (this.failedPeople * 10)
      return Math.round(result)
    },
    avoidLosing () {
      return Math.round(this.failedPeople * 0.78)
    }
  },

  watch: {
    diagnosis (value) {
      var csvFile = new Blob([this.getCsv(value)], { type: 'text/csv' })
      var downloadLink = window.URL.createObjectURL(csvFile)
      this.csvUrl = downloadLink
    },
    async tab (value) {
      if (this.response && this.response.neverbounce) {
        this.neverBounceStatus = (await axios.get(`neverbounce/${this.response.neverbounce.job.job_id}/status`)).data
        this.deliverabilityChartData = [
          ['Error', 'Objects'],
          ['Bad Syntax', this.neverBounceStatus.total.bad_syntax],
          ['Duplicated', this.neverBounceStatus.total.duplicates],
          ['Invalid', this.neverBounceStatus.total.invalid],
          ['Unknown', this.neverBounceStatus.total.unknown],
          ['Disposable', this.neverBounceStatus.total.disposable],
          ['Catch All', this.neverBounceStatus.total.catchall]
        ]
      }
    }
  },

  methods: {
    async choosePlan (name) {
      this.plan.name = name
      const addons = []
      if (this.plan === 'Concierge') {
        if (this.plan.bounced) addons.push('bounced')
        if (this.plan.customer) addons.push('customer')
        if (this.plan.deduplication) addons.push('deduplication')
        if (this.plan.enrichment) addons.push('enrichment')
      }
      if (addons.length) this.plan.addons = addons
      await axios.post(`plans/${name}`, this.plan)
    },
    getCsv (objects) {
      var csv = ''
      for (var o of objects) {
        for (var key of Object.keys(o)) {
          csv += o[key] + ','
        }
        csv += '\n'
      }
      return csv
    },
    async trylogin () {
      this.error = null
      var user = await this.login(this.password, this.email)
      if (!user) return
      this.$router.push('/profile')
    },

    async login (password, email) {
      try {
        var data = (await axios.post(`${process.env.VUE_APP_API_ENDPOINT}/auth/login`, { password, email, includes: ['UserPermissions.Permission'] })).data.data
        this.$store.commit('login', data.user)
        this.$store.commit('token', data.token)
      } catch (error) {
        this.error = (error.response && error.response.data) || error
        return null
      }
      console.log('returned data', data)
      return data.user
    }
  },

  async mounted () {
    if (!this.$store.state.selectedCrm) {
      this.$router.push('/')
      return
    }
    this.crmImage = `./img/${this.$store.state.selectedCrm.crm}.png`
    var response = (await axios.get(`crms/${this.$store.state.selectedCrm.crm}/healthcheck?id=${this.$store.state.selectedCrm.token.key}`)).data
    this.response = response
    this.marketabilityChartData[1][1] = response.marketableProperties
    this.marketabilityChartData[2][1] = response.notMarketableProperties
    this.diagnosis = response.diagnosis
    this.totalRecords = response.total
    this.subtotal = response.subtotal
    this.notMarketableProperties = response.notMarketableProperties
    this.mainPercentage = Math.round(((this.diagnosis.length * 100) / response.total))
    for (var d of this.diagnosis) {
      if (d.object === 'contact') {
        this.totalContacts++
      }
      if (d.object === 'lead') {
        this.totalCompanies++
      }
      if (d.object === 'lead' || d.object === 'contact') {
        this.failedPeople++
        if (d.symptoms.find(s => s.includes('UpToDateRecord'))) {
          this.oldPeopleRecords++
        }
      }
    }
  }
}
</script>
