<template lang="pug">
v-main#crms-view
  v-container.px-10(fluid)
    v-row
      v-col(cols='12')
        h2.display-1.font-weight-light.underline CRMS
    v-row(wrap)
      v-col(cols='12')
        v-container(grid-list-sm, fluid)
          v-row(wrap)
            v-col(v-for='(crm, index) in credentials', v-bind:key="crm.id", cols="12", sm="6", md="3")
              v-card.elevation-4(@click="selectCrm(crm)", flat, tile, style="cursor: pointer; position: relative;")
                v-btn(fab, dark, small, color="red", style="position: absolute; top: -20px; right: -20px;")
                  v-icon(@click="deleteCredential(crm, index)") mdi-delete
                v-img(:src="`./img/${crm.crm}.png`", aspect-ratio='1')
                  template(v-slot:placeholder)
                    v-layout(fill-height, align-center, justify-center, ma-0)
                      v-progress-circular(indeterminate, color='grey lighten-5')
                v-card-title {{ crm.crm }}
                v-card-text
                  v-row.pa-0.ma-0(align="center")
                    v-rating(
                      full-icon="mdi-check"
                      empty-icon="mdi-close"
                      :value="4"
                      color="green"
                      empty-color="red"
                      dense
                      half-increments
                      readonly
                      size="14"
                     )
                    small(class="grey--text ml-4") Helathy Data
                  | Based on the last month health checks data results
                  v-divider
                  v-select(
                    :items="crms",
                    label="Name",
                    v-model="crm.crm"
                  )
                  v-text-field(
                    style="display: none;"
                    label="Client Id"
                    value=""
                    hide-details="auto"
                    v-model="crm.clientId"
                  )
                  v-text-field(
                    label="Secret"
                    hide-details="auto"
                    v-model="crm.secret"
                  )
                v-divider
                v-card-text
                  v-progress-circular(v-if="crm.loading", indeterminate, color="primary")
                  v-btn(v-if="!crm.loading && crm.status !== 'connected'", @click="connectToCrm(crm)") Connect
                  v-chip-group(v-if="!crm.loading", column)
                    v-chip(v-if="crm.status === 'connected'", class="green accent-4 white--text") connected
                    v-chip(v-if="crm.status === 'offline'", class="grey accent-4 white--text") offline
                    v-chip(v-if="crm.status === 'failed'", class="red accent-4 white--text") failed
                  v-btn(v-if="crm.status === 'connected'", @click="checkCRM(crm)") Do Health Check
                  // span {{ JSON.stringify(crm) }}
        v-icon(@click="credentials.push({ loading: false, status: 'offline', crm: null, clientId: '', secret: '' })") mdi-plus
</template>

<style>
</style>

<script>
import axios from 'axios'

export default {
  components: { },
  name: 'CRMS',
  data: function () {
    return {
      crms: ['Pipedrive', 'Hubspot'],
      loading: false,
      credentials: []
    }
  },

  computed: {
    //
  },

  watch: {
    //
  },

  methods: {
    async deleteCredential (crm, index) {
      this.loading = true
      var result = null
      try {
        result = (await axios.delete(`credentials/${crm._id}`).data)
        this.credentials.splice(index, 1)
      } catch (err) {
        result = err
      }
      console.log('deleting result', result)
      this.loading = false
    },
    async connectToCrm (crm) {
      this.loading = true
      var result = null
      try {
        result = (await axios.post(`crm/${crm.crm || crm.name}/api-token`, {
          clientId: crm.clientId,
          secret: crm.secret,
          userId: this.$store.state.cognitoUserId
        })).data
        crm.status = 'connected'
        crm.token = {
          key: result.key
        }
      } catch (err) {
        console.log('error on result', err)
        crm.status = 'failed'
      } //
      this.loading = false
    },
    selectCrm (crm) {
      this.$store.commit('selectCrm', crm)
    },
    checkCRM (crm) {
      this.$store.commit('selectCrm', crm)
      this.$router.push('/health-check')
    }
  },

  async created () {
    const creds = (await axios.get(`users/${this.$store.state.cognitoUserId}/credentials`)).data
    if (creds.data) {
      this.credentials = creds.data
    }
  }
}
</script>
