<template lang="pug">
.navigation
  v-navigation-drawer(dark, v-model="drawer", app, color="#161E2E")
    v-list(dense)
      v-list-item
        v-list-item-content
          v-list-item-title(class="title")
            v-img(
              max-height="55",
              max-width="162",
              src="/img/trujay-branding.svg"
            )

      v-list-item(@click="$router.push('/health-check')")
        v-list-item-action
          v-icon mdi-bottle-tonic-plus-outline
        v-list-item-content
          v-list-item-title Health Check

      v-list-item(@click="$router.push('/')")
        v-list-item-action
          v-icon mdi-account
        v-list-item-content
          v-list-item-title Select a CRM

      v-list-item(@click="logout", v-if="$store.state.user")
        v-list-item-action
          v-icon mdi-logout
        v-list-item-content
          v-list-item-title Logout

  v-app-bar(app)
    v-app-bar-nav-icon(@click.stop="drawer = !drawer")
    v-toolbar-title.d-flex.justify-center.align-center
      strong Health Check
      v-icon mdi-chevron-right
      | {{ currentRouteName }}
</template>

<style>
.navigation {
  background: #161E2E;
}
</style>

<script>
export default {
  name: 'Navigation',

  data: function () {
    return {
      drawer: false
    }
  },
  computed: {
    // Show the selected crm
    title () {
      if (this.$store.state.selectedCrm) {
        return this.$store.state.selectedCrm.name || this.$store.state.selectedCrm.crm + ': ' + this.$store.state.selectedCrm.secret.substr(-3)
      }
      return 'HealthCheck '
    },
    currentRouteName () {
      const name = this.$route.name
      if (name === 'Diagnosis' && this.$store.state.selectedCrm) {
        const crm = this.$store.state.selectedCrm
        const rawDate = new Date(crm.updatedAt)
        return `${crm.crm} as of ${rawDate.toUTCString()}`
      } else {
        return name
      }
    },
    entityName () {
      return this.$route.params.entity && this.$store.state.schema
        ? this.$store.state.schema.find(f => f.type === this.$route.params.entity).humanNamePlural
        : null
    }
  },
  methods: {
    logout () {
      this.$store.commit('logout')
      this.$router.push('/login')
    }
  }
}
</script>
