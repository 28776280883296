import axios from 'axios'
import auth from './../modules/auth'
import store from './../store'

axios.defaults.baseURL = process.env.VUE_APP_API_ENDPOINT
axios.defaults.headers.common = {
  Accept: 'application/json,application/hal+json,text/html,application/xhtml+xml,application/xml;q=0.9,image/webp,*/*;q=0.8',
  'Content-Type': 'application/json;charset=UTF-8'
}

const errorHandler = (error) => {
  store.state.http.is_loading = false
  if (error.response.status === 403) {
    console.log('forbidden content')
  }
  return Promise.reject(error)
}

axios.interceptors.request.use(config => {
  store.state.http.is_loading = true
  return {
    ...config,
    headers: {
      ...config.headers,
      common: {
        ...config.headers.common,
        Authorization: `Bearer ${auth.auth.signInUserSession.getAccessToken().jwtToken}`
      }
    }
  }
})

axios.interceptors.response.use(
  response => {
    store.state.http.is_loading = false
    return response
  },
  error => errorHandler(error)
)

export default axios
