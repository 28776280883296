import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)
var token = window.localStorage.getItem('token') || null
var user = null
try {
  user = JSON.parse(window.localStorage.getItem('user'))
} catch {
  user = null
}

export default new Vuex.Store({
  state: {
    cognitoInfo: {},
    loggedIn: false,
    loadingState: true,
    errorLoadingState: false,
    selectedCrm: null,
    cognitoUserId: '1rhg765465d7g67e45d6',
    crms: [],
    http: {
      is_loading: false
    },
    token: token,
    user: user,
    schema: null,
    formConfig: {
      fieldSize: 3,
      vertical: true
    },
    errors: {

    }
  },
  mutations: {
    selectCrm (state, crm) {
      state.selectedCrm = crm
      window.localStorage.setItem('selectedCrm', JSON.stringify(crm))
    },
    setCRMS (state, data) {
      state.crms = data
    },
    pushCRM (state, crm) {
      state.crms.push(crm)
      window.localStorage.setItem('crms', JSON.stringify(this.crms))
    },
    removeCRM (state, index) {
      state.crms.splice(index, 1)
    },
    mutate (state, data) {
      state[data.field] = data.value
    },
    SET_LOADING (state, status) {
      state.http.is_loading = status
    },
    login (state, data) {
      console.log(state, data)
      state.user = data.user
      state.token = data.token
      window.localStorage.setItem('token', data.token)
      window.localStorage.setItem('user', JSON.stringify(data.user))
    },
    logout (state) {
      state.user = null
      state.token = null
      window.localStorage.removeItem('token')
      window.localStorage.removeItem('user')
    }
  },
  actions: {
    setLoggedIn (newValue) {
      this.state.loggedIn = newValue
    },
    setLoggedOut () {
      this.state.loggedIn = false
      this.state.cognitoInfo = {}
    },
    setCognitoInfo (newValue) {
      this.state.cognitoInfo = newValue
    },
    set_loading: ({ commit }, status) => {
      commit('SET_LOADING', status)
    },
    async loadCrms ({ commit }) {
      var crms = []
      var selectedCrm = null
      try {
        // crms = JSON.parse(window.localStorage.getItem('crms'))
        selectedCrm = JSON.parse(window.localStorage.getItem('selectedCrm'))
      } catch {
        // crms = []
        selectedCrm = null
      }
      commit('setCRMS', crms)
      commit('selectCrm', selectedCrm)
    }
  },
  getters: {
    is_loading: state => {
      return state.ajax.is_loading
    }
  },
  modules: {
    //
  }
})
